// const baseURL = 'https://alexa-security-report.enabot.com'
const baseURL = 'http://localhost:8888'

const APILIST = {
    reportSubmitUrl: "/report/submit"
}

const base = function () {
    let api = {}
    for (let k in APILIST) {
        api[k] = baseURL + APILIST[k]
    }
    return api
}

export default {
    APIS: base()
}