<template>
  <div class="alexa">
    <img src="../assets/logo@2x.9d0ab70.png" style="float: right;width: 15%; padding: 3px 0">
    <h1>Proof of Concept</h1>
    <el-divider/>
    <p>The proof of concept is the most important part of your report submission. Clear,
      reproducible steps will help us validate this issue as quickly as possible.</p>
    <h3>Title<sup style="color: red">*</sup></h3>

    <el-input type="textarea" autosize="autosize" v-model="title_text" :rows="1" placeholder="A clear and concise title includes the type of vulnerability and the impacted asset."/>

<!--    <el-button type="primary" v-on:click="test">click</el-button>-->
    <h3>Description<sup style="color: red">*</sup></h3>

    <p>What is the vulnerability? In clear steps, how do you reproduce it?</p>

    <h4>Summary:</h4>
    <el-input type="textarea" autosize="autosize" v-model="description_summary_text" placeholder="add summary of the vulnerability"></el-input>

    <h4>Steps To Reproduce:</h4>
    <el-input type="textarea" autosize="autosize" v-model="description_steps_text" placeholder="add details for how we can reproduce the issue"></el-input>

    <h4>Supporting Material/References:</h4>
    <el-input type="textarea" autosize="autosize" v-model="description_supporting_text" placeholder="list any additional material (e.g. screenshots, logs, etc.)"></el-input>

    <h4>IP Address:</h4>
    <el-input type="textarea" autosize="autosize" v-model="description_ip_text" placeholder="please list your IP address that you used so we can identify your traffic"></el-input>

    <h4>Timestamp:</h4>
    <el-input type="textarea" autosize="autosize" v-model="description_timestamp_text" placeholder="please list your date and time when you performed testing"></el-input>

    <h3>Impact<sup style="color: red">*</sup></h3>

    <el-input type="textarea" autosize="autosize" v-model="impact_text" placeholder="What security impact could an attacker achieve?"/>

    <h3>Email</h3>
    <el-input type="textarea" autosize="autosize" v-model="user_email_address" placeholder="Enter your email to receive updates on the status of your submission. If you want to stay
anonymous you can leave this field blank."/>

    <h3>Discovery details</h3>
    <p>Additional information about the discovery of the vulnerability you're reporting.</p>
    <h4>Time spent</h4>
    <el-input type="textarea" autosize="autosize" v-model="time_spent_text" placeholder="Help us understand how long it took you to find this vulnerability"/>


    <h3>Upload Video</h3>

    <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Drag & drop or <em>select more files from your computer</em></div>
      <div class="el-upload__tip" >max. 10MB per file</div>
    </el-upload>

    <h1>About video proof of concepts</h1>

    <div class="video-warning">
      <p>Video proof of concepts are a great tool to provide evidence of a security
        vulnerability. It can also improve the clarity of your report if you're struggling to
        describe the vulnerability. However, please note that a video proof of concept should not replace a written report. This functionality is designed to enhance the
        reporting experience. Excessive use may result in additional report rate limiting.</p>
    </div>


    <el-card class="box-card">
      <div class="clearfix" style="text-align: center">
        <span style="font-style: inherit">Review and Submit your report</span>
        <el-button class="submit_button" style="float: right; padding: 3px 0" type="text" @click="submit_btn_click">Submit</el-button>
        <el-divider/>
      </div>
      <div>Please review your report details before submitting your report as editing after report
        submission is not possible.</div>

<!--      <h3>Title</h3>-->
<!--      <p>{{title_text}}</p>-->
      <h3>Description</h3>
      <h4>Summary:</h4>
      <p>{{description_summary_text}}</p>
      <h4>Steps To Reproduce:</h4>
      <p>{{description_steps_text}}</p>
      <h4>Supporting Material/References:</h4>
      <p>{{description_supporting_text}}</p>
      <h4>IP Address:</h4>
      <p>{{description_ip_text}}</p>
      <h4>Timestamp:</h4>
      <p>{{description_timestamp_text}}</p>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "AlexaSecurityReport",

  methods : {
    test: function(event) {
      if (event) {
        alert(this.textarea)
      }
    },

    submit_btn_click: function (event) {
      if (event) {
        var messagebody =  {
          title_text: this.title_text,
          description_summary_text: this.description_summary_text,
          description_steps_text: this.description_steps_text,
          description_supporting_text: this.description_supporting_text,
          description_ip_text: this.description_ip_text,
          description_timestamp_text: this.description_timestamp_text,
          impact_text: this.impact_text,
          user_email_address: this.user_email_address,
          time_spent_text: this.time_spent_text,
        }

        this.axios.post(
            this.api.APIS.reportSubmitUrl,
            messagebody
        ).then(response => {
          console.log(response)
          this.open_submit_success_messagebox()
        }).catch(reason => {
          console.log(reason)
        })
      }
    },

    open_submit_success_messagebox: function () {
      this.$alert('If you do not receive a reply within five business days, it means your report was not accepted. Please do not publicly disclose these details without express written consent from Enabot',
          'Success', {
            confirmButtonText: 'Confirm',
            callback: () => {
              this.$message({
                type: 'info',
                message: 'Submit Success.'
              });
            }
          });
    }
  },

  data() {
    return {
      title_text: '',
      description_summary_text: '',
      description_steps_text: '',
      description_supporting_text: '',
      description_ip_text: '',
      description_timestamp_text: '',
      impact_text: '',
      user_email_address: '',
      time_spent_text: '',

    }
  }
}
</script>

<style scoped>

  .video-warning {
    padding: 8px 16px;
    background-color: #fff6f7;
    border-radius: 4px;
    border-left: 5px solid #fe6c6f;
    margin: 20px 0;
  }

  .box-card {
    width: -moz-fit-content;
  }
</style>