import Vue from 'vue'
import App from './App.vue'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'

import enabotApi from "@/enabot-api";

Vue.prototype.api = enabotApi
Vue.use(ElementUI)
Vue.use(VueAxios, axios)

new Vue({
    el: '#app',
    render: h => h(App)
});
